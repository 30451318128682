import { injectable } from 'tsyringe';
import { MessageComposer } from '@/modules/timeline/domain/message-composer/MessageComposer';
import { SmsChannel } from '@/modules/timeline/domain/message-composer/channel/SmsChannel';
import { WhatsAppChannel } from '@/modules/timeline/domain/message-composer/channel/WhatsAppChannel';
import { DpChatChannel } from '@/modules/timeline/domain/message-composer/channel/DpChatChannel';
import type { WhatsAppMessage } from '@/modules/timeline/domain/message-composer/message/WhatsAppMessage';
import type { StoreChannels } from '@/modules/timeline/adapters/message-composer/StoreChannels';
import type { StorePatient } from '@/modules/timeline/adapters/message-composer/StorePatient';
import type { StoreTask } from '@/modules/timeline/adapters/StoreTask';
import { HttpConversationRepository } from '@/modules/timeline/adapters/message-composer/message/HttpConversationRepository';
import { HttpMediaRepository } from '@/modules/timeline/adapters/message-composer/message/media/HttpMediaRepository';
import { PhoneNumber } from '@/modules/timeline/domain/message-composer/PhoneNumber';
import { ConversationId } from '@/modules/timeline/domain/message-composer/ConversationId';

@injectable()
export class StoreMessageComposerFactory {
  constructor(
    private readonly messageRepository: HttpConversationRepository,
    private readonly mediaRepository: HttpMediaRepository,
  ) {}

  make(
    task: StoreTask,
    patient: StorePatient,
    messages: WhatsAppMessage[],
    channels: StoreChannels,
  ): MessageComposer {
    if (!channels.has_text_channel) {
      return this.makeWithoutChannels(task, patient);
    }

    if (channels.type === 'whatsapp') {
      return this.makeWhatsApp(task, patient, messages);
    }

    if (channels.type === 'sms') {
      return this.makeSms(task, patient);
    }

    if (channels.type === 'dp-chat') {
      return this.makeChat(task, patient);
    }

    throw new Error('Unsupported channel type');
  }

  makeWithoutChannels(task: StoreTask, patient: StorePatient): MessageComposer {
    return new MessageComposer(
      [],
      PhoneNumber.of(patient.phoneNumber),
      ConversationId.of(task.conversation),
      task.status,
    );
  }

  makeSms(task: StoreTask, patient: StorePatient): MessageComposer {
    return new MessageComposer(
      [new SmsChannel(this.messageRepository)],
      PhoneNumber.of(patient.phoneNumber),
      ConversationId.of(task.conversation),
      task.status,
    );
  }

  makeChat(task: StoreTask, patient: StorePatient): MessageComposer {
    return new MessageComposer(
      [new DpChatChannel(this.messageRepository, this.mediaRepository)],
      PhoneNumber.of(patient.phoneNumber),
      ConversationId.of(task.conversation),
      task.status,
    );
  }

  makeWhatsApp(
    task: StoreTask,
    patient: StorePatient,
    messages: WhatsAppMessage[],
  ): MessageComposer {
    return new MessageComposer(
      [new WhatsAppChannel(messages, this.messageRepository, this.mediaRepository)],
      PhoneNumber.of(patient.phoneNumber),
      ConversationId.of(task.conversation),
      task.status,
    );
  }
}
