import type { Owner } from '@/modules/task/domain/owner/Owner';
import type { OwnerRepository } from '@/modules/task/domain/owner/OwnerRepository';
import type { TimelineEvent } from '@/modules/task/domain/timeline/TimelineEvent';
import { OwnerId } from '@/modules/task/domain/owner/OwnerId';
import { IsoDate } from '@/modules/task/domain/time/IsoDate';
import type { TimezoneRepository } from '@/modules/task/domain/time/TimezoneRepository';
import type { Message } from '@/modules/task/domain/message/Message';
import type { MediaType } from '@/modules/task/domain/message/MediaTypes';

export class DpChatMessage implements TimelineEvent, Message {
  constructor(
    readonly id: string,
    readonly direction: 'inbound' | 'outbound',
    readonly createdAt: string,
    readonly body = '',
    readonly ownerId: OwnerId = OwnerId.empty,
    readonly mediaType: MediaType | null = null,
  ) {}

  get isInbound(): boolean {
    return this.direction === 'inbound';
  }

  get isOutbound(): boolean {
    return this.direction === 'outbound';
  }

  get hasOwner(): boolean {
    return !this.ownerId.isEmpty;
  }

  get hasBody(): boolean {
    return this.body.trim() !== '';
  }

  get hasMedia(): boolean {
    return !!this.mediaType;
  }

  get isSentBySystem(): boolean {
    return this.ownerId.isEmpty && this.isOutbound;
  }

  get isSuccessfulOutbound(): boolean {
    return this.isOutbound && !this.isSentBySystem;
  }

  get isSuccessfulInbound(): boolean {
    return this.isInbound;
  }

  displayedDate(timezoneRepository: TimezoneRepository): IsoDate {
    return this.createdAt !== ''
      ? IsoDate.make(this.createdAt, timezoneRepository.get())
      : IsoDate.empty;
  }

  isMine(ownerRepository: OwnerRepository): boolean {
    return this.isOwnedBy(ownerRepository.getMe());
  }

  isOwnedBy(owner: Owner): boolean {
    return owner.id.isEqual(this.ownerId);
  }
}
