import type { CSModeEnabledChecker } from './customer-success-mode/CSModeEnabledChecker';
import { roleName } from './customer-success-mode/roleName';
import type { Facility } from './facility/Facility';
import type { PolicyUser } from './policy/PolicyUser';
import type { Subscription } from './subscription/Subscription';

export class User implements PolicyUser {
  private static alwaysValidatedFeatures = [
    'PATIENT_IMPORTER_SAAS_ID',
    'PATIENT_REQUESTS',
    'PATIENT_CREATION',
  ];

  constructor(
    readonly id: string,
    readonly userRoles: string[],
    readonly csModeEnabledChecker: CSModeEnabledChecker,
    readonly facility: Facility | null,
    readonly subscription: Subscription | null,
  ) {}

  isFacilityUser(): boolean {
    if (!this.facility) {
      return false;
    }

    return this.facility.facilityUsers.some(facilityUser => facilityUser.id === this.id);
  }

  isManager(): boolean {
    if (!this.facility) {
      return false;
    }

    // we do check permissions here with "_acl", so CS are considered as managers
    return this.facility.accessType === 'EDIT';
  }

  isReceptionist(): boolean {
    if (!this.facility) {
      return false;
    }

    return this.facility.accessType === 'VIEW';
  }

  hasOpenGate(permission: string): boolean {
    return this.userRoles.includes(`ROLE_GATE_KEEPER_${permission}`);
  }

  hasRole(name: string): boolean {
    return this.userRoles.includes(`ROLE_${name}`);
  }

  hasFeature(featureName: string): boolean {
    if (this.hasEnabledCSMode()) {
      const isAlwaysValidatedFeature = User.alwaysValidatedFeatures.includes(featureName);
      return isAlwaysValidatedFeature ? this.isFeatureActive(featureName) : true;
    }

    return this.isFeatureActive(featureName);
  }

  private isFeatureActive(featureName: string): boolean {
    return this.subscription?.features.some(feature => feature.name === featureName) ?? false;
  }

  private hasEnabledCSMode(): boolean {
    return this.hasRole(roleName) && this.csModeEnabledChecker.isEnabled();
  }
}
