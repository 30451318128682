import { injectable } from 'tsyringe';
import type { PatientInboundChatAccess } from '../../domain/patient/PatientInboundChatAccess';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { TaskListTaskFetcher } from '../task/TaskListTaskFetcher';

@injectable()
export class HttpPatientInboundChatAccess implements PatientInboundChatAccess {
  constructor(
    private readonly httpClient: HttpClientApi,
    private readonly taskFetcher: TaskListTaskFetcher,
  ) {}

  async enableWriting(patientId: string, taskId: string): Promise<void> {
    await this.httpClient.put(`/api/v0/facility/{facilityId}/patient/${patientId}/dp-chat`, {
      canReply: true,
    });
    await this.taskFetcher.getForChatAccessChange(taskId);
  }

  async disableWriting(patientId: string, taskId: string): Promise<void> {
    await this.httpClient.put(`/api/v0/facility/{facilityId}/patient/${patientId}/dp-chat`, {
      canReply: false,
    });
    await this.taskFetcher.getForChatAccessChange(taskId);
  }
}
