import type { DpProvider } from '@/core/di/DpProvider';
import type { DependencyContainer } from 'tsyringe';
import { Authorization } from '../../domain/Authorization';
import {
  accessAllFacilityFeatures,
  addPatientManually,
  importSaasPatientId,
} from '../../domain/customer-success-mode/policy';
import { roleName } from '../../domain/customer-success-mode/roleName';

export class AuthorizationProvider implements DpProvider {
  boot(container: DependencyContainer): void {
    const authorization = container.resolve(Authorization);

    authorization.addPolicies([
      {
        name: accessAllFacilityFeatures,
        handler: user => user.hasRole(roleName),
      },

      {
        name: importSaasPatientId,
        handler: user => user.hasFeature('PATIENT_IMPORTER_SAAS_ID'),
      },

      {
        name: addPatientManually,
        handler: user => user.hasFeature('PATIENT_CREATION'),
      },
    ]);
  }
}
