import { schema } from 'normalizr';
import { EntityTypes } from '@/api/types/entities';

export const patientSchema = new schema.Entity(
  EntityTypes.Patient,
  {},
  {
    processStrategy(value) {
      // Store v0 as v1 key, required for easily restoring to v1 format.
      const {
        phone_number,
        phoneNumber,
        isDpChatInboundCommunicationAllowed,
        is_dp_chat_inbound_communication_allowed,
        ...rest
      } = value;

      return {
        ...rest,
        phoneNumber: phoneNumber ?? phone_number,
        isDpChatInboundCommunicationAllowed:
          isDpChatInboundCommunicationAllowed ?? is_dp_chat_inbound_communication_allowed ?? null,
      };
    },
  },
);
