import type { InputPatient, InputPatientCollection } from './input';
import { Patient } from '../domain/Patient';
import { PatientCollection } from '../domain/PatientCollection';
import { PatientType } from '../domain/PatientType';

export class PatientFactory {
  private static readonly typeMap = {
    employee: PatientType.Employee,
    blocked: PatientType.Blocked,
  };

  make(patient: InputPatient): Patient {
    return new Patient(
      patient.id,
      patient.name,
      patient.surname,
      patient.phoneNumber,
      this.getPatientTypes(patient.types ?? []),
      patient.priority,
      patient.note,
      patient.url ?? '',
      patient.isDpChatInboundCommunicationAllowed,
    );
  }

  makeCollection(jsonApiPatients: InputPatientCollection): PatientCollection {
    const patients: InputPatient[] = jsonApiPatients.data.map(item => item.attributes);
    const totalNumberOfPatients = jsonApiPatients.meta.totalItems;

    return new PatientCollection(this.makeMany(patients), totalNumberOfPatients);
  }

  tryMakeFirst(patients: InputPatient[] | null): Patient | null {
    if (!patients || patients.length === 0) {
      return null;
    }

    return this.make(patients[0]);
  }

  private makeMany(patients: InputPatient[]): Patient[] {
    return patients.map(patient => this.make(patient));
  }

  private getPatientTypes(types: string[]): PatientType[] {
    return types.map(type => PatientFactory.typeMap[type]).filter(type => !!type);
  }
}
