import { injectable } from 'tsyringe';
import { TaskIdQueueSupervisor } from '../../domain/real-time-updates/task-id/TaskIdQueueSupervisor';

@injectable()
export class TaskFetcher {
  constructor(private readonly taskIdQueue: TaskIdQueueSupervisor) {}

  async get(taskId: string, trigger: string[] = []): Promise<void> {
    await this.taskIdQueue.addToQueue(taskId, trigger);
  }
}
