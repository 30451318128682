export enum FilePreviewTheme {
  Gray = 'gray',
  Primary = 'primary',
}

export const PreviewFileType = {
  Pdf: 'pdf',
  Spreadsheet: 'spreadsheet',
  Document: 'document',
} as const;

export type FileType = (typeof PreviewFileType)[keyof typeof PreviewFileType];
