import type { PhoneNumber } from '@/modules/timeline/domain/message-composer/PhoneNumber';
import type { TextChannel } from './TextChannel';
import type { ConversationRepository } from '../ConversationRepository';
import type { ConversationId } from '../ConversationId';
import type { MediaRepository } from '../message/media/MediaRepository';
import { DPChatError } from '../errors/DPChatError';

export class DpChatChannel implements TextChannel {
  constructor(
    private readonly messageRepository: ConversationRepository,
    private readonly mediaRepository: MediaRepository,
  ) {}

  readonly hasToInitializeWithTemplate = false;

  readonly canSendFreeFormMessages = true;

  readonly canSendAttachments = true;

  readonly canSendVoiceRecordings = false;

  readonly messageLengthLimit = 5000;

  async sendMessage(
    conversationId: ConversationId,
    phoneNumber: PhoneNumber,
    body: string,
    mediaUrl?: string,
  ): Promise<void> {
    if (!conversationId.value) {
      return this.messageRepository.create(phoneNumber, body);
    }

    try {
      if (mediaUrl) {
        const media = await this.mediaRepository.uploadFile(mediaUrl);

        return this.messageRepository.addMessage(conversationId, body, media.id);
      }

      return this.messageRepository.addMessage(conversationId, body);
    } catch (e) {
      throw new DPChatError(e.message);
    }
  }
}
