export class DPChatError extends Error {
  private messageContent: string;

  static isDeletedPatientError(errorMessage: string): boolean {
    return /patient with id.*found/.test(errorMessage);
  }

  private static getErrorMessage(errorMessage: string): string {
    return DPChatError.isDeletedPatientError(errorMessage)
      ? 'dpchat-error-patient-deleted'
      : 'dpchat-error-generic';
  }

  get translationKey(): string {
    return DPChatError.getErrorMessage(this.messageContent);
  }

  constructor(message: string) {
    super(DPChatError.getErrorMessage(message));
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, DPChatError.prototype);

    this.name = 'DPChatError';
    this.messageContent = message;
  }
}
