import type { MediaProxy } from '../../../MediaProxy';

export class Media {
  constructor(
    readonly id: string,
    readonly contentType: string,
    readonly name: string,
    private readonly originUrl: string,
    private readonly mediaProxy: MediaProxy | null = null,
  ) {}

  get url(): string {
    if (this.isInlineData) {
      return this.originUrl;
    }
    if (!this.mediaProxy) {
      return this.originUrl;
    }

    return this.mediaProxy.make(this.originUrl);
  }

  get isImage(): boolean {
    return this.type === 'image';
  }

  get isAudio(): boolean {
    return this.type === 'audio';
  }

  get isVideo(): boolean {
    return this.type === 'video';
  }

  get isPdf(): boolean {
    return this.contentType === 'application/pdf';
  }

  get isWordDoc(): boolean {
    return (
      this.contentType === 'application/msword' ||
      this.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
  }

  get isSpreadsheet(): boolean {
    return this.contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  get isPresentation(): boolean {
    return (
      this.contentType ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    );
  }

  private get type(): string {
    const [type] = this.contentType.split('/');

    return type;
  }

  private get isInlineData() {
    return this.originUrl.startsWith('data:');
  }
}
