import type { PatientType } from './PatientType';

export class Patient {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly surname: string,
    readonly phoneNumber: string,
    readonly types: PatientType[] = [],
    readonly priority = 0,
    readonly note = '',
    readonly url = '',
    readonly isDpChatInboundCommunicationAllowed: boolean | null = null,
  ) {}

  get fullName(): string {
    return `${this.name} ${this.surname}`.trim();
  }

  static empty(): Patient {
    return new Patient('', '', '', '');
  }

  static makeFromPhoneNumber(phoneNumber: string): Patient {
    return new Patient('', '', '', phoneNumber);
  }
}
