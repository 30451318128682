import { injectable } from 'tsyringe';
import { WhatsAppMessage } from '@/modules/task/domain/message/WhatsAppMessage';
import { OwnerId } from '@/modules/task/domain/owner/OwnerId';
import type { TimelineWhatsAppMessage, TimelineMedia } from '@/modules/timeline/public/types';
import { TimelineSmsMessage } from '@/modules/timeline/public/types';
import { TimelineDpChatMessage } from '@/modules/timeline/public/api/data-transfer/message/TimelineDpChatMessage';
import { SmsMessage } from '@/modules/task/domain/message/SmsMessage';
import { DpChatMessage } from '@/modules/task/domain/message/DpChatMessage';
import type { MediaType } from '@/modules/task/domain/message/MediaTypes';

@injectable()
export class MessageFactory {
  make(
    data: TimelineWhatsAppMessage | TimelineSmsMessage | TimelineDpChatMessage,
  ): WhatsAppMessage | SmsMessage | DpChatMessage {
    if (data instanceof TimelineSmsMessage) {
      return new SmsMessage(
        data.id,
        data.direction,
        data.createdAt,
        data.body,
        data.ownerId ? new OwnerId(data.ownerId) : OwnerId.empty,
      );
    }

    if (data instanceof TimelineDpChatMessage) {
      return new DpChatMessage(
        data.id,
        data.direction,
        data.createdAt,
        data.body,
        data.ownerId ? new OwnerId(data.ownerId) : OwnerId.empty,
        this.mapMedia(data.media[0]),
      );
    }

    return new WhatsAppMessage(
      data.id,
      data.status,
      data.direction,
      data.createdAt,
      data.body,
      data.ownerId ? new OwnerId(data.ownerId) : OwnerId.empty,
      this.mapMedia(data.media[0]),
    );
  }

  private mapMedia(media?: TimelineMedia): MediaType | null {
    const contentType = media?.contentType || null;
    if (!media || !contentType) {
      return null;
    }

    const type = contentType?.split('/')[0];

    if (type === 'image') {
      return 'image';
    }

    if (type === 'audio') {
      return 'audio';
    }

    if (type === 'video') {
      return 'video';
    }

    if (contentType === 'application/pdf') {
      return 'pdf';
    }

    if (
      contentType === 'application/msword' ||
      contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return 'document';
    }

    if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return 'spreadsheet';
    }

    if (
      contentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      return 'presentation';
    }

    return null;
  }
}
