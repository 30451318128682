import type { Patient } from '../../domain';
import type { OutputPatient } from './OutputPatient';

export class OutputPatientFactory {
  make(patient: Patient): OutputPatient {
    return {
      id: patient.id,
      name: patient.name,
      surname: patient.surname,
      fullName: patient.fullName,
      phoneNumber: patient.phoneNumber,
      note: patient.note,
      url: patient.url,
      priority: patient.priority,
      types: patient.types,
      isDpChatInboundCommunicationAllowed: patient.isDpChatInboundCommunicationAllowed,
    };
  }
}
