enum WhitelistedPrefixes {
  Andorra = '+376',
  Austria = '+43',
  Belgium = '32',
  Brazil = '+55',
  Cyprus = '+357',
  Czechia = '+420',
  Denmark = '+45',
  Finland = '+358',
  France = '+33',
  Germany = '+49',
  Gibraltar = '+350',
  Greece = '+30',
  Ireland = '+353',
  Italy = '+39',
  Luxembourg = '+352',
  Malta = '+356',
  Mexico = '+52',
  Netherlands = '+31',
  Norway = '+47',
  Poland = '+48',
  Portugal = '+351',
  Slovakia = '+421',
  Spain = '+34',
  Sweden = '+46',
  Switzerland = '+41',
  UnitedArabEmirates = '+971',
  UnitedKingdom = '+44',
  UnitedStates = '+1',
}

export class Caller {
  constructor(readonly phoneNumber: string) {}

  get hasWhitelistedNumber(): boolean {
    return Object.values(WhitelistedPrefixes).some(whitelistedPrefix =>
      this.phoneNumber.startsWith(whitelistedPrefix),
    );
  }
}
