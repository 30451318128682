<template>
  <div :class="$b({ theme, active })">
    <div :class="$b('search-wrapper')">
      <dp-multiselect
        ref="dpMultiselect"
        v-model="valueSynced"
        :class="$b('search')"
        :options="suggestionOptions"
        track-by="id"
        item-label="name"
        :placeholder="placeholderDescription"
        :internal-search="false"
        :searchable="true"
        :allow-empty="true"
        :show-no-results="true"
        :clear-on-select="true"
        :close-on-select="true"
        :preserve-search="true"
        size="lg"
        @search-change="searchChange"
        @select="onSearchedItem"
        @close="onClose"
        @open="onOpen"
      >
        <template #noResultMessage>
          <div v-if="isLoading" :class="$b('loading')">
            <div class="spinner-wrapper bg-gray-100">
              <dp-spinner color="gray-300" />
            </div>
          </div>
          <div v-else :class="$b('no-options')">
            {{ noOptionsMessage }}
          </div>
        </template>

        <template #noOptionsMessage>
          <div v-if="isLoading" :class="$b('loading')">
            <div class="spinner-wrapper bg-gray-100">
              <dp-spinner color="gray-300" />
            </div>
          </div>
          <div v-else :class="$b('no-options')">
            {{ noOptionsMessage }}
          </div>
        </template>

        <template #singleLabel>
          {{ singleLabel }}
        </template>

        <template #option="{ option }">
          <patient-suggestion-line
            v-if="option.type === 'patient'"
            :class="$b('suggestion-line')"
            :name="option.fullName"
            :phone-number="option.formattedNumber"
          />
          <text-suggestion-line v-else :class="$b('suggestion-line')" :content="option.id" />
        </template>
      </dp-multiselect>
      <dp-icon-search :class="$b('search-icon')" color="gray-600" :size="24" />
    </div>
    <dp-button v-if="isCallable" :class="$b('append')" link-type="muted" @click="callHandler">
      <dp-icon-phone :class="$b('call-icon')" :size="24" />
    </dp-button>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Model, Prop, Ref, Vue } from 'vue-facing-decorator';
import { DpButton, DpMultiselect, DpSpinner } from '@dp-ui-kit/vue';
import { DpIconPhone, DpIconSearch } from '@dp-ui-kit/icons';

import { isIncludedIn } from '@/utils/functions';
import { PatientSuggestionLine, TextSuggestionLine } from '@/ui/atoms';
import { PatientSearchTheme } from './PatientSearchInput.types';
import type { PatientSearchItem } from './PatientSearchInput.types';

interface MultiselectApi {
  updateSearch(value: string): void;

  search: string;
}

@Component({
  name: 'PatientSearchInput',
  components: {
    DpSpinner,
    DpMultiselect,
    DpIconSearch,
    DpIconPhone,
    DpButton,
    PatientSuggestionLine,
    TextSuggestionLine,
  },
})
export default class PatientSearchInput extends Vue {
  @Model({ type: Object, default: null })
  valueSynced: PatientSearchItem;

  @Prop({ type: Array, default: () => [] })
  readonly options: PatientSearchItem[];

  @Prop({ type: Boolean, default: false })
  readonly isLoading: boolean;

  @Prop({ type: String, default: '' })
  readonly placeholderDescription: string;

  @Prop({ type: String, default: '' })
  readonly noOptionsMessage: string;

  @Prop({ type: Boolean, default: true })
  readonly isCallable: boolean;

  @Prop({
    type: String,
    default: PatientSearchTheme.White,
    validator: isIncludedIn(Object.values(PatientSearchTheme)),
  })
  readonly theme: PatientSearchTheme;

  @Ref
  readonly dpMultiselect: DpMultiselect;

  active = false;

  searchQuery = '';

  get multiselect(): null | MultiselectApi {
    if (!this.dpMultiselect) {
      return null;
    }

    return this.dpMultiselect.$refs.multi ?? null;
  }

  get hasOptions() {
    return this.options.length > 0;
  }

  get suggestionOptions(): PatientSearchItem[] {
    if (!this.hasOptions && this.searchQuery.length > 0) {
      return [
        {
          type: 'text' as const,
          id: this.searchQuery,
          uri: this.searchQuery,
        },
      ];
    }

    return this.options;
  }

  get singleLabel() {
    return this.valueSynced.type === 'patient' ? this.valueSynced.fullName : this.valueSynced.id;
  }

  callHandler(): void {
    if (this.searchQuery.length === 0) {
      return;
    }

    this.$emit('call', this.searchQuery);
    this.clearSearch();
  }

  @Emit('search-change')
  searchChange(phrase: string): string {
    this.active = true;
    this.searchQuery = phrase;
    return phrase;
  }

  @Emit('search')
  onSearchedItem(item: PatientSearchItem): PatientSearchItem {
    return item;
  }

  clearSearch() {
    this.searchQuery = '';
    this.multiselect?.updateSearch('');
  }

  onClose() {
    this.active = false;
  }

  setSearch() {
    this.searchQuery = this.multiselect?.search ?? this.searchQuery;
  }

  onOpen() {
    this.active = false;
    this.setSearch();
  }

  setValue(value: PatientSearchItem | null) {
    if (value) {
      this.valueSynced = value;
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

$icon-size: v.$spacer-lg;
$search-padding: v.$spacer-md * 0.75;
$search-height: v.$input-height-lg;

$theme-map: (
  'white': (
    bg-color: v.$white,
  ),
  'light-gray': (
    bg-color: v.$gray-200,
  ),
);
.dp-patient-search-input {
  display: flex;
  align-items: center;
  border: 1px solid v.$gray-300;
  border-radius: 0.2em;
  background-color: v.$gray-200;

  @each $type, $map in $theme-map {
    &--theme_#{$type} {
      background-color: map_get($map, bg-color);
    }
  }

  &:focus-within {
    background-color: v.$white;
  }

  &__search-wrapper {
    position: relative;
    flex-grow: 1;
  }

  &__search {
    margin-bottom: 0;
    min-height: $search-height;
    border-right: 1px solid v.$gray-300;

    :deep(*) {
      white-space: nowrap;
    }

    :deep(.multiselect__caret-wrapper) {
      display: none;
    }

    :deep(.multiselect__content-wrapper) {
      min-width: min-content;
      display: none;
    }

    :deep(.multiselect__tags) {
      border: 0;
      padding-left: $icon-size + 2 * $search-padding;
      box-shadow: none;
      background-color: inherit;
    }

    :deep(.multiselect__single) {
      background-color: inherit;
    }

    :deep(.multiselect__placeholder) {
      overflow: hidden;
    }
  }

  &--active &__search {
    :deep(.multiselect__content-wrapper) {
      display: block;
    }
  }

  &__search-icon {
    position: absolute;
    flex-shrink: 0;
    height: $search-height;
    left: $search-padding;
    top: 50%;
    transform: translateY(-50%);

    /* overwrite position from ui-kit -> .svg-icon:not(.svg-icon-v2) */
    &.svg-icon:not(.svg-icon-v2) {
      position: absolute;
    }
  }

  &__loading {
    padding: v.$spacer-xl;
  }

  &__no-options {
    display: inline-block;
    padding: v.$spacer-lg;
  }

  &__append {
    display: flex;
    align-items: center;
    height: 100%;
    padding: v.$spacer-xs v.$spacer-sm;
  }

  &:focus-within &__append {
    cursor: pointer;
  }

  &__call-icon {
    vertical-align: middle;
    fill: v.$gray-600;
  }

  &:focus-within &__call-icon {
    fill: v.$primary;
  }
}
</style>
