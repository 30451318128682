import { TaskFetcher as TaskListApiTaskFetcher } from '@/modules/tasks-list/public/api/TaskFetcher';
import { injectable } from 'tsyringe';
import type { TaskFetcher } from '../../domain/task/TaskFetcher';

@injectable()
export class TaskListTaskFetcher implements TaskFetcher {
  constructor(private readonly taskFetcher: TaskListApiTaskFetcher) {}

  async getForChatAccessChange(id: string): Promise<void> {
    await this.taskFetcher.get(id, ['FE::dp-chat-patient-allowed-conversation']);
  }
}
