<template>
  <button :class="$b({ readonly })" :style="inlineCss" type="button" @click="$emit('click')">
    <component
      :is="component"
      v-dpp-tooltip="{ content: tooltipText }"
      :color="iconColor"
      :size="size"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    />
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DpIconEmptyStar, DpIconStar } from '@dp-ui-kit/icons';
import type { TranslateResult } from 'vue-i18n';

import { dppTooltip } from '@/ui/directives/dpp-tooltip';

export const ICON_SIZES = [8, 12, 16, 18, 20, 24, 32, 48, 64];
type IconSize = typeof ICON_SIZES;

@Component({
  name: 'PriorityButton',
  emits: ['click'],
  components: {
    DpIconEmptyStar,
    DpIconStar,
  },
  directives: {
    dppTooltip,
  },
})
export default class PriorityButton extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly isActive: boolean;

  @Prop({
    type: Number,
    default: 20,
    validator: (size: number) => ICON_SIZES.includes(size),
  })
  readonly size: IconSize;

  @Prop({
    type: String,
    default: '',
  })
  readonly tooltipContent: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly readonly: boolean;

  isHovered = false;

  get component() {
    return this.isHovered || !this.isActive ? DpIconEmptyStar : DpIconStar;
  }

  get tooltipText(): TranslateResult {
    if (this.tooltipContent) {
      return this.tooltipContent;
    }

    if (this.readonly) {
      return '';
    }

    return this.isActive ? this.$t('settings.priority.unmark') : this.$t('settings.priority.mark');
  }

  get inlineCss() {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
    };
  }

  get iconColor(): 'warning' | 'gray-500' {
    if ((this.isActive && this.isHovered) || (!this.isActive && !this.isHovered)) {
      return 'gray-500';
    }

    return 'warning';
  }

  onMouseEnter(): void {
    if (this.readonly) {
      return;
    }

    this.isHovered = true;
  }

  onMouseLeave(): void {
    if (this.readonly) {
      return;
    }

    this.isHovered = false;
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/variables' as v;

.dp-priority-button {
  display: inline-block;

  &--readonly {
    cursor: not-allowed;
  }
}
</style>
